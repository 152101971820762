import * as React from 'react';

function SvgTwitter(props) {
  return (
    <svg viewBox="0 0 50.45 41" {...props}>
      <path
        d="M50.45 4.85c-1.86.82-3.85 1.38-5.94 1.63A10.35 10.35 0 0049.06.75c-2 1.19-4.22 2.05-6.57 2.51C40.6 1.26 37.91 0 34.93 0c-5.72 0-10.35 4.63-10.35 10.35 0 .81.09 1.6.27 2.36-8.61-.43-16.23-4.55-21.34-10.82a10.375 10.375 0 003.2 13.82c-1.7-.05-3.29-.52-4.69-1.29v.13c0 5.01 3.57 9.2 8.3 10.15-.87.24-1.78.36-2.73.36-.67 0-1.32-.07-1.95-.19 1.32 4.11 5.14 7.1 9.67 7.19a20.745 20.745 0 01-12.85 4.43c-.84 0-1.66-.05-2.47-.14A29.327 29.327 0 0015.87 41c19.04 0 29.45-15.77 29.45-29.45 0-.45-.01-.9-.03-1.34 2.02-1.46 3.77-3.28 5.16-5.36z"
        fill="#020203"
      />
    </svg>
  );
}

export default SvgTwitter;
