import React from 'react';
import styled from 'styled-components';
import { adaptForLargeScreen } from '@utils/adaptForLargeScreen';
import { Link } from 'gatsby';
import {
  BODY_FONT_SMALL,
  INNER_MARGIN_LEFT_MOBILE,
  INNER_MARGIN_RIGHT_MOBILE,
} from '@config/layout';
import { Twitter, Instagram, LinkedIn } from './icons';
import { mobileMQ, tinyMobileMQ } from '@config/mediaQueries';

const Container = styled.div(
  adaptForLargeScreen({
    height: 434,
    backgroundColor: '#FF2C2C',
    color: '#000',
    paddingLeft: 32,
    paddingRight: 32,
    paddingTop: 41,
    paddingBottom: 41,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    [mobileMQ]: {
      paddingLeft: INNER_MARGIN_LEFT_MOBILE,
      paddingRight: INNER_MARGIN_RIGHT_MOBILE,
      height: 535,
    },
  }),
);

const Text = styled.div(
  adaptForLargeScreen({
    marginBottom: 28,
    fontSize: 60,
    [mobileMQ]: { fontSize: 36 },
  }),
);

const TextThin = styled.div({
  fontFamily: 'F37-Beckett-Thin',
  [mobileMQ]: {
    display: 'inline',
  },
});

const ContactLink = styled.a(
  adaptForLargeScreen({
    textDecoration: 'underline',
    fontFamily: 'F37-Beckett-Bold',

    [mobileMQ]: {
      display: 'inline',
    },
  }),
);

const FooterTextContainer = styled.div(
  adaptForLargeScreen({
    display: 'flex',
    justifyContent: 'space-between',
    [mobileMQ]: { flexDirection: 'column' },
  }),
);

const FooterText = styled.div(
  adaptForLargeScreen({
    fontSize: BODY_FONT_SMALL,

    [tinyMobileMQ]: {
      fontSize: BODY_FONT_SMALL * 0.85,
    },
  }),
);

const FooterCopyright = styled(FooterText)({
  [mobileMQ]: { order: 2, marginTop: 30 },
});

const FooterLink = styled(Link)(
  adaptForLargeScreen({
    textDecoration: 'underline',
    marginLeft: 40,
    [mobileMQ]: {
      marginLeft: 0,
      marginRight: 40,
    },
  }),
);

const Icons = styled.div(
  adaptForLargeScreen({
    display: 'flex',
  }),
);

const Icon = styled.a(
  adaptForLargeScreen({
    display: 'block',
    width: 54,
    height: 54,
    marginRight: 15,

    [mobileMQ]: {
      marginRight: 25,
    },
  }),
);

export const Footer = () => (
  <Container>
    <div>
      <Text>
        <TextThin>If you would like to talk about a new project</TextThin>{' '}
        <ContactLink href="mailto:annabel@face37.com">Get in touch</ContactLink>
      </Text>

      <Icons>
        <Icon href="https://www.instagram.com/__Face37__/" target="_blank">
          <Instagram style={{ width: '100%', height: '100%' }} />
        </Icon>
        <Icon href="https://twitter.com/_face37/" target="_blank">
          <Twitter style={{ width: '100%', height: '100%' }} />
        </Icon>
        <Icon
          href="https://www.linkedin.com/company/face37-ltd/"
          target="_blank"
        >
          <LinkedIn style={{ width: '100%', height: '100%' }} />
        </Icon>
      </Icons>
    </div>
    <FooterTextContainer>
      <FooterCopyright>
        Face37 Ltd. © {new Date().getFullYear()}
      </FooterCopyright>
      <FooterText>
        <FooterLink to="/cookies">Cookie Policy</FooterLink>
        <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
      </FooterText>
    </FooterTextContainer>
  </Container>
);
