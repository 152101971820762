import { CONTENT_URL } from '@config/urls';

export const getSrc = (path: string) => `${CONTENT_URL}/${encodeURI(path)}`;

export const getVideoSrcSized = (videoPath: string, size: string): string => {
  if (!videoPath) return '';

  const path = videoPath
    .replace('uploads/video', 'videos_resized')
    .replace('.', `__${size}.`);

  return `${CONTENT_URL}/${encodeURI(path)}`;
};

export const getImageSrcSized = (image: CmsImage, size: string): string => {
  if (image === undefined) return '';

  const path = image.path
    .replace('uploads/images', 'images_resized')
    .replace('.', `__${size}.`);

  return `${CONTENT_URL}/${encodeURI(path)}`;
};
