import React from 'react';
import styled from 'styled-components';

import { Dropdown } from './Dropdown';
import { formatPrice } from '@utils/formatPrice';

interface IProps {
  price: string;
  shippingUk: string;
  shippingEu: string;
  shippingInternational: string;
  backgroundColor: string;
  foregroundColor: string;
  error: boolean;
  onChange: (shipping: string) => void;
}

const Container = styled.div({
  marginBottom: 19,
  zIndex: 1,
  position: 'relative',
});

const RegionPicker = (props: IProps) => {
  const shippings = [
    props.shippingUk,
    props.shippingEu,
    props.shippingInternational,
  ];

  return (
    <Container>
      <Dropdown
        placeholder="Select region:"
        options={[
          `UK (£${formatPrice(props.price)} + £${formatPrice(
            props.shippingUk,
          )} P&P)`,
          `Europe (£${formatPrice(props.price)} + £${formatPrice(
            props.shippingEu,
          )} P&P)`,
          `Worldwide (£${formatPrice(props.price)} + £${formatPrice(
            props.shippingInternational,
          )} P&P)`,
        ]}
        onSelectItem={(index: number) => {
          props.onChange(shippings[index]);
        }}
        backgroundColor={props.backgroundColor}
        foregroundColor={props.foregroundColor}
        error={props.error}
      />
    </Container>
  );
};

export default RegionPicker;
