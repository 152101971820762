import React, { FC } from 'react';
import { getImageSrcSized } from '@utils/assets';

type Props = {
  image: CmsImage;
  className?: string;
  allowedSizes?: string[];
  onLoad?: () => void;
};

export const ResponsiveImage: FC<Props> = ({
  image,
  className,
  allowedSizes,
  onLoad,
}) => (
  <picture onLoad={onLoad}>
    {(!allowedSizes || allowedSizes.indexOf('large') > -1) && (
      <source
        media="(min-width: 1800px)"
        srcSet={getImageSrcSized(image, 'large')}
      />
    )}
    {(!allowedSizes || allowedSizes.indexOf('medium') > -1) && (
      <source
        media="(min-width: 769px)"
        srcSet={getImageSrcSized(image, 'medium')}
      />
    )}
    {(!allowedSizes || allowedSizes.indexOf('small') > -1) && (
      <img className={className} src={getImageSrcSized(image, 'small')} />
    )}
  </picture>
);
