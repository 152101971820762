import React from 'react';
import styled from 'styled-components';

import { Dropdown } from './Dropdown';
import { formatPrice } from '@utils/formatPrice';

interface IProps {
  variants: CmsShopVariant[];
  backgroundColor: string;
  foregroundColor: string;
  initialItemIndex: number;
  error: boolean;
  onChange: (variant: CmsShopVariant) => void;
}

const Container = styled.div({
  marginBottom: 19,
  zIndex: 2,
  position: 'relative',
});

const VariantPicker = (props: IProps) => {
  return (
    <Container>
      <Dropdown
        placeholder="Select variant:"
        options={props.variants.map(
          variant => `${variant.name} (£${formatPrice(variant.price)})`,
        )}
        onSelectItem={(index: number) => {
          props.onChange(props.variants[index]);
        }}
        backgroundColor={props.backgroundColor}
        foregroundColor={props.foregroundColor}
        error={props.error}
        initialItemIndex={props.initialItemIndex}
      />
    </Container>
  );
};

export default VariantPicker;
