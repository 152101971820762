import * as React from 'react';

function SvgInstagram(props) {
  return (
    <svg
      id="Instagram_svg__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 42 42"
      xmlSpace="preserve"
      {...props}
    >
      <style>{'.Instagram_svg__st0{fill:#020203}'}</style>
      <path
        className="Instagram_svg__st0"
        d="M21 3.78c5.61 0 6.27.02 8.49.12 2.05.09 3.16.44 3.9.72.98.38 1.68.84 2.41 1.57.73.73 1.19 1.43 1.57 2.41.29.74.63 1.85.72 3.9.1 2.21.12 2.88.12 8.49s-.02 6.27-.12 8.49c-.09 2.05-.44 3.16-.72 3.9-.38.98-.84 1.68-1.57 2.41-.73.73-1.43 1.19-2.41 1.57-.74.29-1.85.63-3.9.72-2.21.1-2.88.12-8.49.12s-6.27-.02-8.49-.12c-2.05-.09-3.16-.44-3.9-.72-.98-.38-1.68-.84-2.41-1.57a6.498 6.498 0 01-1.57-2.41c-.29-.74-.63-1.85-.72-3.9-.1-2.21-.12-2.88-.12-8.49s.02-6.27.12-8.49c.09-2.03.43-3.15.72-3.89.38-.98.84-1.67 1.57-2.41.73-.73 1.43-1.19 2.41-1.57.74-.29 1.85-.63 3.9-.72 2.22-.1 2.88-.13 8.49-.13M21 0c-5.7 0-6.42.02-8.66.13-2.24.1-3.76.46-5.1.98-1.38.53-2.55 1.25-3.72 2.41A10.462 10.462 0 001.1 7.24C.58 8.58.23 10.1.12 12.34.02 14.58 0 15.3 0 21s.02 6.42.13 8.66c.1 2.24.46 3.76.98 5.1.54 1.38 1.25 2.55 2.42 3.72 1.17 1.17 2.34 1.89 3.72 2.42 1.34.52 2.86.87 5.1.98 2.23.1 2.95.12 8.65.12s6.42-.02 8.66-.13c2.24-.1 3.76-.46 5.1-.98 1.38-.54 2.55-1.25 3.72-2.42 1.17-1.17 1.89-2.34 2.42-3.72.52-1.34.87-2.86.98-5.1.1-2.23.12-2.95.12-8.65s-.02-6.42-.13-8.66c-.1-2.24-.46-3.76-.98-5.1-.54-1.38-1.25-2.55-2.42-3.72-1.17-1.17-2.34-1.89-3.72-2.42-1.34-.52-2.86-.87-5.1-.98C27.42.02 26.7 0 21 0z"
      />
      <path
        className="Instagram_svg__st0"
        d="M21 10.22c-5.96 0-10.78 4.83-10.78 10.78S15.04 31.78 21 31.78 31.78 26.96 31.78 21 26.96 10.22 21 10.22zM21 28c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"
      />
      <circle className="Instagram_svg__st0" cx={32.21} cy={9.79} r={2.52} />
    </svg>
  );
}

export default SvgInstagram;
