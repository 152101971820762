import * as React from 'react';

function SvgLinkedIn(props) {
  return (
    <svg viewBox="0 0 42 42" {...props}>
      <path
        d="M38.89 0H3.1C1.39 0 0 1.36 0 3.03v35.94C0 40.64 1.39 42 3.1 42h35.79C40.6 42 42 40.64 42 38.97V3.03C42 1.36 40.6 0 38.89 0zM12.46 35.79H6.22V15.75h6.24v20.04zM9.34 13.01c-2 0-3.61-1.62-3.61-3.61 0-1.99 1.61-3.61 3.61-3.61 1.99 0 3.61 1.62 3.61 3.61 0 1.99-1.61 3.61-3.61 3.61zm26.45 22.78h-6.23v-9.75c0-2.32-.04-5.31-3.24-5.31-3.24 0-3.74 2.53-3.74 5.15v9.91h-6.22V15.75h5.97v2.74h.09c.83-1.58 2.86-3.24 5.89-3.24 6.31 0 7.47 4.15 7.47 9.55v10.99z"
        fill="#020203"
      />
    </svg>
  );
}

export default SvgLinkedIn;
