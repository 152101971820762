import React, { useLayoutEffect, FC } from 'react';
import styled from 'styled-components';

import UIStore from '@stores/UIStore';
import { adaptForLargeScreen } from '@utils/adaptForLargeScreen';
import { ShopDetailDetails } from './ShopDetailDetails';
import {
  PAGE_MARGIN_TOP,
  PAGE_MARGIN_TOP_MOBILE,
  BODY_FONT_LARGE,
  INNER_MARGIN,
  BODY_LETTER_SPACING_LARGE,
  BODY_FONT_SMALL,
  INNER_MARGIN_LEFT_MOBILE,
  INNER_MARGIN_RIGHT_MOBILE,
} from '@config/layout';
import { StyledMarkdown } from './StyledMarkdown';
import { mobileMQ } from '@config/mediaQueries';
import { ResponsiveImage } from './ResponsiveImage';
import { ShopDetailProps } from 'src/templates/ShopDetail';

const VERTICAL_SPACING = 147;

const Container = styled.div(
  adaptForLargeScreen({
    marginTop: PAGE_MARGIN_TOP,
    [mobileMQ]: { marginTop: PAGE_MARGIN_TOP_MOBILE, marginBottom: 105 },
  }),
);

const TopContainer = styled.div(
  adaptForLargeScreen({
    display: 'flex',
    marginBottom: VERTICAL_SPACING,

    [mobileMQ]: {
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: 50,
    },
  }),
);

const TopRightContainer = styled.div(
  adaptForLargeScreen({
    display: 'block',
  }),
);

const TopImage = styled(ResponsiveImage)(
  adaptForLargeScreen({
    height: 500,
    marginBottom: 118,
    marginLeft: 115,
    display: 'block',

    [mobileMQ]: {
      display: 'none',
    },
  }),
);

const TopImageMobile = styled(ResponsiveImage)({
  display: 'none',

  [mobileMQ]: {
    display: 'block',
    width: '65vw',
    marginLeft: -10,
    marginBottom: 55,
  },
});

const Description = styled.div(
  adaptForLargeScreen({
    fontSize: BODY_FONT_LARGE,
    marginRight: 163,
    marginLeft: 138,
    letterSpacing: BODY_LETTER_SPACING_LARGE,

    [mobileMQ]: {
      width: '100vw',
      margin: 0,
      paddingLeft: 15,
      paddingRight: 20,
      fontSize: BODY_FONT_SMALL,
      lineHeight: 1.5,
    },
  }),
);

const MiddleImage = styled(ResponsiveImage)<{ hasBottomImages?: boolean }>(p =>
  adaptForLargeScreen({
    width: '100vw',
    marginBottom: VERTICAL_SPACING,

    [mobileMQ]: {
      marginBottom: p.hasBottomImages ? 80 : 0,
    },
  }),
);

const BottomGrid = styled.div(
  adaptForLargeScreen({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: 20,
    gridRowGap: 33,
    marginLeft: INNER_MARGIN,
    marginRight: INNER_MARGIN,

    [mobileMQ]: {
      display: 'block',
      marginLeft: INNER_MARGIN_LEFT_MOBILE,
      marginRight: INNER_MARGIN_RIGHT_MOBILE,
    },
  }),
);

const BottomGridImage = styled(ResponsiveImage)(
  adaptForLargeScreen({
    width: '100%',

    [mobileMQ]: {
      marginBottom: 30,
    },
  }),
);

export const ShopDetail: FC<ShopDetailProps> = ({ entry }) => {
  useLayoutEffect(() => {
    UIStore.setupPage(entry.backgroundColour, entry.foregroundColour);
  });

  const hasBottomImages = !!(entry.bottomImages && entry.bottomImages.length);

  return (
    <Container>
      <TopContainer>
        <TopImageMobile image={entry.topImage} />

        <ShopDetailDetails entry={entry} />

        <TopRightContainer>
          <TopImage image={entry.topImage} />

          <Description>
            <StyledMarkdown source={entry.description} />
          </Description>
        </TopRightContainer>
      </TopContainer>

      <MiddleImage
        image={entry.middleImage}
        hasBottomImages={hasBottomImages}
      />

      {hasBottomImages && (
        <BottomGrid>
          {entry.bottomImages.map((image, i) =>
            image ? (
              <BottomGridImage key={i} image={image as CmsImage} />
            ) : (
              <div />
            ),
          )}
        </BottomGrid>
      )}
    </Container>
  );
};
