import React, { Fragment, useEffect } from 'react';

import { Meta } from '@components/Meta';
import { ShopDetail } from '@components/ShopDetail';
import { Footer } from '@components/Footer';
import { Layout } from '@components/Layout';

export type ShopDetailProps = {
  entry: CmsShopEntry;
} & BasePageProps;

type Props = { pageContext: ShopDetailProps };

export default ({ pageContext: { entry, homepageFonts } }: Props) => {
  return (
    <Fragment>
      <Meta title={`Shop | ${entry.name}`} />

      <Layout homepageFonts={homepageFonts}>
        <ShopDetail entry={entry} />
      </Layout>

      <Footer />
    </Fragment>
  );
};
