import React, { FC, useState, useRef } from 'react';
import OutsideClickHadler from 'react-outside-click-handler';
import styled, { keyframes, css } from 'styled-components';
import Color from 'color';

import { adaptForLargeScreen } from '@utils/adaptForLargeScreen';
import { mobileMQ, isIPhone } from '@config/mediaQueries';

type Props = {
  options: string[];
  backgroundColor: string;
  foregroundColor: string;
  placeholder: string;
  initialItemIndex?: number;
  error: boolean;
  onSelectItem: (index: number) => void;
};

const errorAnimation = keyframes`
0% { opacity: 1; }
50% { opacity: 0; }
100% { opacity: 1; }`;

const Container = styled.div<{
  backgroundColor: string;
  foregroundColor: string;
  error: boolean;
}>(
  p => ({
    ...adaptForLargeScreen({
      backgroundColor: p.backgroundColor,
      position: 'relative',
      zIndex: 1,
      display: 'flex',
      height: 62,
      fontSize: 20,
      justifyContent: 'space-between',
      borderColor: p.foregroundColor,
      borderStyle: 'solid',
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 15,
      paddingRight: 15,
      width: '100%',
      cursor: 'pointer',
      userSelect: 'none',
    }),
    borderWidth: 2,
    ...(!p.error && { animation: 'none !important' }),

    [mobileMQ]: {
      fontSize: 16,
    },
  }),
  css`
    animation: ${errorAnimation} 1s ease both 3;
  `,
);

const UpArrow = styled.div<{ color: string }>(p =>
  adaptForLargeScreen({
    width: 0,
    height: 0,
    display: 'inline-block',
    borderRightWidth: 15 / 2,
    borderRightStyle: 'solid',
    borderRightColor: 'transparent',
    borderBottomWidth: 15,
    borderBottomStyle: 'solid',
    borderBottomColor: p.color,
    borderLeftWidth: 15 / 2,
    borderLeftStyle: 'solid',
    borderLeftColor: 'transparent',
  }),
);

const DownArrow = styled(UpArrow)(
  adaptForLargeScreen({
    transform: 'rotate(180deg)',
  }),
);

const ArrowContainer = styled.div({
  marginTop: -2, // not adapted as border size is fixed due to weird rendering

  [mobileMQ]: {
    marginTop: 0,
  },
});

const AccessibleSelect = styled.select(
  adaptForLargeScreen({
    position: 'absolute',
    left: -9999,
  }),
);

const OptionList = styled.div<{
  foregroundColor: string;
  backgroundColor: string;
}>(p => ({
  ...adaptForLargeScreen({
    position: 'absolute',
    zIndex: 2,
    top: 59,
    left: 0,
    backgroundColor: p.backgroundColor,
    outlineStyle: 'solid',
    outlineColor: p.foregroundColor,
    width: '100%',
  }),
  outlineWidth: 2,
}));

const Option = styled.div<{ highlightColor: string }>(p =>
  adaptForLargeScreen({
    height: 60,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    position: 'relative',
    zIndex: 2,

    ':hover': {
      backgroundColor: new Color(p.highlightColor).alpha(0.1).string(),
      cursor: 'pointer',
    },
  }),
);

const CurrentOption = styled.div(
  adaptForLargeScreen({ marginTop: -2, [mobileMQ]: { marginTop: 0 } }),
);

export const Dropdown: FC<Props> = props => {
  const [open, setOpen] = useState(false);
  const [activeOptionIndex, setActiveOptionIndex] = useState(
    props.initialItemIndex !== undefined ? props.initialItemIndex : -1,
  );
  const selectRef = useRef(null as HTMLSelectElement);

  const toggle = () => {
    if (isIPhone()) selectRef.current.focus();
    else setOpen(!open);
  };

  const selectOption = (index: number) => {
    setActiveOptionIndex(index);
    props.onSelectItem(index);
  };

  const handleOutsideClick = () => {
    setOpen(false);
  };

  const { options } = props;

  return (
    <OutsideClickHadler onOutsideClick={handleOutsideClick}>
      <AccessibleSelect
        ref={selectRef}
        onChange={e =>
          selectOption(options.findIndex(o => o === e.target.value))
        }
        onBlur={e => selectOption(options.findIndex(o => o === e.target.value))}
      >
        {options.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </AccessibleSelect>

      <Container
        onClick={toggle}
        backgroundColor={props.backgroundColor}
        foregroundColor={props.foregroundColor}
        error={props.error}
      >
        <CurrentOption>
          {activeOptionIndex > -1
            ? options[activeOptionIndex]
            : props.placeholder}
        </CurrentOption>

        {open && (
          <OptionList
            foregroundColor={props.foregroundColor}
            backgroundColor={props.backgroundColor}
          >
            {options.map((option, optionIndex) => (
              <Option
                onClick={() => selectOption(optionIndex)}
                key={option}
                highlightColor={props.foregroundColor}
              >
                {option}
              </Option>
            ))}
          </OptionList>
        )}

        <ArrowContainer>
          {open ? (
            <UpArrow color={props.foregroundColor} />
          ) : (
            <DownArrow color={props.foregroundColor} />
          )}
        </ArrowContainer>
      </Container>
    </OutsideClickHadler>
  );
};
