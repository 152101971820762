import React, { Fragment, useState, useRef } from 'react';
import styled from 'styled-components';

import {
  SITE_PADDING_LEFT,
  INNER_MARGIN,
  BODY_FONT_SMALL,
  INNER_MARGIN_LEFT_MOBILE,
} from '@config/layout';
import { StyledMarkdown } from './StyledMarkdown';
import { adaptForLargeScreen } from '@utils/adaptForLargeScreen';
import RegionPicker from './RegionPicker';
import { formatPrice } from '@utils/formatPrice';
import { mobileMQ } from '@config/mediaQueries';
import VariantPicker from './VariantPicker';

const Container = styled.div(
  adaptForLargeScreen({
    width: 504,
    marginLeft: SITE_PADDING_LEFT,
    flexShrink: 0,
    flexGrow: 0,

    [mobileMQ]: {
      marginLeft: 0,
      width: 'calc(100% - 17px - 17px)',
      marginBottom: 33,
    },
  }),
);

const ContainerInner = styled.div<{ backgroundColor: string }>(p =>
  adaptForLargeScreen({
    position: 'fixed',
    backgroundColor: p.backgroundColor,
    width: 504,

    [mobileMQ]: { position: 'static', width: 'auto' },
  }),
);

const Details = styled.div<{ foregroundColor: string }>(p => ({
  ...adaptForLargeScreen({
    whiteSpace: 'pre',
    fontSize: 30,
    lineHeight: 1.333,
    paddingTop: 34,
    paddingBottom: 41,
    paddingLeft: 29,
    paddingRight: 27,
    borderStyle: 'solid',
    borderColor: p.foregroundColor,
    letterSpacing: 1.5,
  }),
  borderWidth: 2,

  [mobileMQ]: {
    borderWidth: 1,
    fontSize: 25,
    paddingTop: 23,
    paddingBottom: 30,
    paddingLeft: 21,
    paddingRight: 21,
    lineHeight: 1.25,
  },
}));

const BuyContainer = styled.div<{ foregroundColor: string }>(p => ({
  ...adaptForLargeScreen({
    borderTopWidth: 0,
    borderStyle: 'solid',
    borderColor: p.foregroundColor,
    paddingLeft: INNER_MARGIN,
    paddingTop: 24,
    paddingRight: 27,
    paddingBottom: 33,
  }),
  borderWidth: 2,

  [mobileMQ]: {
    borderWidth: 1,
    marginTop: -1,
    paddingLeft: 21,
    paddingRight: 21,
    paddingBottom: 22,
  },
}));

const PriceContainer = styled.div<{ bottomMargin: boolean }>(p =>
  adaptForLargeScreen({
    marginBottom: p.bottomMargin ? 0 : 15,
    marginLeft: 2,
  }),
);

const Price = styled.div(
  adaptForLargeScreen({
    display: 'inline-block',
    fontSize: 50,
    marginRight: 15,
    letterSpacing: 2.5,
  }),
);

const PostageAndPackaging = styled.div(
  adaptForLargeScreen({
    display: 'inline-block',
    fontSize: BODY_FONT_SMALL,

    [mobileMQ]: {
      fontSize: 15,
      display: 'block',
      paddingBottom: 8,
    },
  }),
);

const BuyButton = styled.a<{
  foregroundColor: string;
  backgroundColor: string;
  enabled: boolean;
}>(p =>
  adaptForLargeScreen({
    cursor: p.enabled ? 'pointer' : 'default',
    width: '100%',
    background: p.backgroundColor,
    color: p.foregroundColor,
    fontSize: 40,
    height: 77,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: p.enabled ? 1 : 0.25,
    userSelect: 'none',

    [mobileMQ]: {
      fontSize: 30,
      height: 63,
    },
  }),
);

type Props = {
  entry: CmsShopEntry;
};

export const ShopDetailDetails = ({ entry }: Props) => {
  const formRef = useRef(null as HTMLFormElement);

  const hasVariants = entry.variants && entry.variants.length;
  const initialVariantIndex = hasVariants
    ? entry.variants.findIndex(v => v.defaultVariant) > -1
      ? entry.variants.findIndex(v => v.defaultVariant)
      : 0
    : 0;

  const [selectedShipping, setSelectedShipping] = useState(-1);
  const [selectedVariant, setSelectedVariant] = useState(
    hasVariants
      ? entry.variants[initialVariantIndex]
      : (undefined as CmsShopVariant),
  );

  const price = hasVariants ? selectedVariant.price : entry.price;
  const soldOut = hasVariants ? selectedVariant.soldOut : entry.soldOut;
  const name = `${entry.name}${
    hasVariants ? ` (${selectedVariant.name})` : ''
  }`;

  const [regionError, setError] = useState(false);

  const handleBuyClicked = () => {
    if (selectedShipping > -1) formRef.current.submit();
    else setError(true);
  };

  return (
    <Container>
      <ContainerInner backgroundColor={entry.backgroundColour}>
        <Details foregroundColor={entry.foregroundColour}>
          <StyledMarkdown source={entry.details} />
        </Details>
        <BuyContainer foregroundColor={entry.foregroundColour}>
          <form
            id="paypal"
            name="_xclick"
            action="https://www.paypal.com/cgi-bin/webscr"
            method="post"
            ref={formRef}
          >
            <input type="hidden" name="cmd" value="_xclick" />
            <input type="hidden" name="business" value="buy@face37.com" />
            <input type="hidden" name="currency_code" value="GBP" />
            <input
              type="hidden"
              name="return"
              value="https://face37.com/order-confirmed"
            />
            <input type="hidden" name="item_name" value={name} />
            <input
              type="hidden"
              name="amount"
              value={parseFloat(price) + selectedShipping}
            />
          </form>

          {!entry.soldOut && hasVariants ? (
            <VariantPicker
              backgroundColor={entry.backgroundColour}
              foregroundColor={entry.foregroundColour}
              variants={entry.variants}
              initialItemIndex={initialVariantIndex}
              onChange={v => setSelectedVariant(v)}
              error={false}
            />
          ) : null}

          {!soldOut ? (
            <RegionPicker
              backgroundColor={entry.backgroundColour}
              foregroundColor={entry.foregroundColour}
              onChange={s => setSelectedShipping(parseFloat(s))}
              shippingUk={entry.shipping.uk}
              shippingEu={entry.shipping.eu}
              shippingInternational={entry.shipping.international}
              price={price}
              error={regionError}
            />
          ) : null}

          <PriceContainer bottomMargin={soldOut}>
            {soldOut ? (
              <Price>Sold out</Price>
            ) : (
              <Fragment>
                <Price>
                  £
                  {formatPrice(
                    parseFloat(price) +
                      (selectedShipping > -1 ? selectedShipping : 0),
                    true,
                  )}
                </Price>
                <PostageAndPackaging>
                  {selectedShipping > -1 ? 'Inc.' : 'Plus'} postage &amp;
                  packaging
                </PostageAndPackaging>
              </Fragment>
            )}
          </PriceContainer>
          {!soldOut && (
            <BuyButton
              foregroundColor={entry.buttonTextColour || '#fff'}
              backgroundColor={entry.foregroundColour}
              enabled={selectedShipping > -1}
              onClick={handleBuyClicked}
            >
              Buy
            </BuyButton>
          )}
        </BuyContainer>
      </ContainerInner>
    </Container>
  );
};
